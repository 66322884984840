import {
  GetAdminUserQuery,
  GetUserAndProductQuery
} from 'src/config/generated/graphql'

import { ArrayElement } from './common'

export type UserType = NonNullable<GetUserAndProductQuery['user']>

export type AdminUserType = NonNullable<GetAdminUserQuery['User_by_pk']>

export type AdminMembershipType = ArrayElement<AdminUserType['Memberships']>

export enum UserEventType {
  Unknown = 0,
  Funded,
  Gifted,
  Sponsored,
  MasterPlanter,
  Monitored,
  EarnedPoints,
  AddedToTheCount,
  Planted,
  Volunteered,
  LandSpotting,
  GiftRedemption
}
