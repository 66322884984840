import {
  GetAdminPurchaseQuery,
  GetDashboardTabsDataQuery,
  GetPurchasesForAccountQuery,
  ListAdminPurchasesQuery
} from 'src/config/generated/graphql'

import { ArrayElement } from './common'

// GQL types

export type AdminPurchaseType = Omit<
  GetAdminPurchaseQuery['AdminPurchaseView'][0],
  'FilterState'
> & {
  FilterState: PurchaseFilterState
}

export type AdminListPurchaseType = Omit<
  ListAdminPurchasesQuery['AdminPurchaseView'][0],
  'FilterState'
> & {
  FilterState: PurchaseFilterState
}

export type ListDashboardPurchasesType = ArrayElement<
  GetPurchasesForAccountQuery['PurchaseDashboardView']
>

export type GetDashboardTabsPurchasesType = ArrayElement<
  GetDashboardTabsDataQuery['PurchaseDashboardView']
>

// Frontend types and enums

export enum PurchaseFilterState {
  Ready = 'Ready',
  Waiting = 'Waiting',
  Matched = 'Matched',
  Pending = 'Pending',
  Voided = 'Voided'
}

export enum PurchaseType {
  Unknown = 0,
  FundedTree,
  GiftedTree,
  BulkGifted,
  FundedPlanting,
  RegistryGift
}

export enum PurchaseState {
  Unknown = 0,
  New,
  Processing,
  Pending,
  Processed,
  Dishonored,
  Voided,
  Abandoned,
  Error,
  Refunded,
  Valid
}

export enum PaymentMethod {
  CreditCard = 'CreditCard',
  Invoice = 'Invoice',
  SavedCreditCard = 'SavedCreditCard'
}

export const PURCHASE_TYPE_VALUES = {
  [PurchaseType.Unknown]: 'Unknown',
  [PurchaseType.FundedTree]: 'Funded tree',
  [PurchaseType.GiftedTree]: 'Gifted tree',
  [PurchaseType.BulkGifted]: 'Bulk gifted',
  [PurchaseType.FundedPlanting]: 'Funded planting',
  [PurchaseType.RegistryGift]: 'Registry gift'
}
