import { useMemo } from 'react'

import { ButtonLink } from 'src/components/atoms/Link'
import {
  Heading2,
  Heading3,
  ParagraphIntro
} from 'src/components/atoms/Typography'
import {
  FullWidthContainer,
  FullWidthContainerBackgroundColor
} from 'src/components/layouts/Container'
import { ActionLinkCard } from 'src/components/molecules/ActionLinkCard'

import { Infopanel } from 'src/config/generated/sanitySchema'
import useMediaQuery from 'src/hooks/useMediaQuery'
import { getSanityImageUrl } from 'src/utils/sanity'

type TakeActionSectionProps = {
  cmsData: Infopanel | undefined
  fullWidth?: boolean
  skipContainer?: boolean
}

export const TakeActionSection = ({
  cmsData,
  fullWidth = true,
  skipContainer = false
}: TakeActionSectionProps) => {
  const isMobile = useMediaQuery('(max-width: 1024px)') || !fullWidth
  const cta = useMemo(() => {
    const ctaData = cmsData?.ctas?.length ? cmsData.ctas[0] : null

    if (!ctaData) return null

    const isExternal = !!ctaData.link
    return (
      <ButtonLink
        key={ctaData._key}
        variant={ctaData.variant}
        color={ctaData.color}
        isExternal={isExternal}
        href={isExternal ? ctaData.link! : ctaData.route!}
        size={ctaData.variant !== 'text' && fullWidth ? 'large' : 'base'}
      >
        {ctaData.title}
      </ButtonLink>
    )
  }, [cmsData, fullWidth])

  if (!cmsData) return null

  return (
    <FullWidthContainer
      skipContainer={skipContainer}
      backgroundColor={FullWidthContainerBackgroundColor.aquaSpring}
      id='section-action'
      verticalPadding={fullWidth ? 'base' : 'short'}
      className='lg:py-[100px]'
    >
      <Heading2>{fullWidth ? cmsData.title : 'Join the action'}</Heading2>
      {fullWidth ? <ParagraphIntro>{cmsData.subtitle}</ParagraphIntro> : null}
      <div
        className={`mt-5 lg:mt-10 flex flex-col gap-[15px] ${
          fullWidth ? 'lg:gap-5 lg:flex-row' : ''
        }`}
      >
        <div
          className={`flex flex-col flex-1 gap-[15px] ${
            fullWidth ? 'lg:justify-between' : ''
          }`}
        >
          <ActionLinkCard
            title='Gift native tree e-cards'
            type='gift'
            fullWidth={fullWidth}
          />
          <ActionLinkCard
            title='Donate to planting projects'
            type='donate'
            fullWidth={fullWidth}
          />
          <ActionLinkCard
            title='Crowdfund for native trees'
            type='fundraiser'
            fullWidth={fullWidth}
          />
        </div>
        <div
          className={`flex flex-col flex-1 bg-white p-5 gap-[15px] bg-none ${
            fullWidth ? 'lg:p-10 lg:gap-5 lg:bg-auto' : ''
          }`}
          style={{
            backgroundImage:
              cmsData.image && !isMobile
                ? `url(${getSanityImageUrl(cmsData.image)})`
                : 'none',
            backgroundSize: '118px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 36px bottom 36px'
          }}
        >
          <Heading3 className='!m-0'>
            Make native trees part of your business sustainability journey.
          </Heading3>
          <ul className='list-check flex-1'>
            <li>Enhance biodiversity, mitigate CO2 emissions, restore NZ.</li>
            <li>Use our marketing toolkit to share your impact.</li>
            <li>Fund or gift native trees to clients or employees.</li>
          </ul>
          <div className='justify-self-end'>{cta}</div>
        </div>
      </div>
    </FullWidthContainer>
  )
}
